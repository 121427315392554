import { v4 as uuidv4 } from "uuid";
import {
  copyTextToClipboard,
  getRandomInt,
  sortAscending,
  sortDescending,
} from "~/shared/utils";
import { CustomMetricRule, MetricsFilter } from "~/types/Metrics";
import { ReportSnapshot, ReportSnapshotResponse } from "~/types/ReportSnapshot";
import {
  TestingLogColumn,
  TestingLogColumnCell,
  TestingLogColumnCellResponse,
  TestingLogColumnGroup,
  TestingLogColumnGroupResponse,
  TestingLogColumnResponse,
  TestingLogReport,
  TestingLogReportResponse,
  TestingLogView,
  TestingLogViewResponse,
} from "~/types/TestingLog";
import { Provider, Sort } from "~/types/shared";

export const useTestingLog = () => {
  const getMappedTestingLogReportSnapshpts = (
    snapshots: Array<ReportSnapshotResponse<TestingLogReportResponse>>,
  ): Array<ReportSnapshot<TestingLogReport>> => {
    return snapshots.map((snapshot) => {
      return {
        id: snapshot.id,
        uuid: snapshot.uuid,
        type: snapshot.type,
        createdAt: new Date(snapshot.createdAt),
        data: getMappedTestingLogReports([snapshot.data])[0],
        creator: snapshot.creator,
        reportId: snapshot.reportId,
      };
    });
  };

  const getMappedTestingLogReports = (
    reports: Array<TestingLogReportResponse>,
  ): Array<TestingLogReport> => {
    return reports.map((report) => {
      return {
        ...report,
        startDate: report.startDate != null ? new Date(report.startDate) : null,
        endDate: report.endDate != null ? new Date(report.endDate) : null,
        views: getMappedTestingLogViews(report.views),
      };
    });
  };

  const getMappedTestingLogViews = (
    views: Array<TestingLogViewResponse>,
  ): Array<TestingLogView> => {
    return views.map((viewRes) => {
      return {
        info: viewRes.info,
        columns: getMappedTestingLogColumns(viewRes.columns),
      };
    });
  };

  const getMappedTestingLogColumns = (
    columns: Array<TestingLogColumnResponse>,
  ): Array<TestingLogColumn> => {
    return columns
      .map((columnRes) => {
        return {
          info: columnRes.info,
          cells: getMappedTestingLogCells(columnRes.cells),
        };
      })
      .sort((a, b) => sortAscending(a.info.order, b.info.order));
  };

  const getMappedTestingLogCells = (
    cells: Array<TestingLogColumnCellResponse>,
  ): Array<TestingLogColumnCell> => {
    return cells
      .map((cellRes) => {
        return {
          order: cellRes.order,
          groups: getMappedTestingLogGroups(cellRes.groups),
        };
      })
      .sort((a, b) => sortAscending(a.order, b.order));
  };

  const getMappedTestingLogGroups = (
    groups: Array<TestingLogColumnGroupResponse>,
  ): Array<TestingLogColumnGroup> => {
    const { getMappedCreatives } = useCreatives();
    return groups
      .map((groupRes) => {
        const images =
          groupRes.creatives.length > 0
            ? groupRes.creatives.flatMap((c) =>
                Array.isArray(c.info.images) && c.info.images.length > 0
                  ? [c.info.images[0]]
                  : [],
              )
            : [];

        const videos =
          groupRes.creatives.length > 0
            ? groupRes.creatives.flatMap((c) =>
                Array.isArray(c.info.videos) && c.info.videos.length > 0
                  ? [c.info.videos[0]]
                  : [],
              )
            : [];

        const video =
          Array.isArray(videos) && videos.length > 0 ? videos[0] : null;

        const image =
          Array.isArray(images) && images.length > 0 ? images[0] : null;

        return {
          info: {
            ...groupRes.info,
            images,
            videos,
            image,
            video,
          },
          creatives: getMappedCreatives(groupRes.creatives),
          total: groupRes.total,
          pageNumber: groupRes.pageNumber,
        };
      })
      .sort((a, b) => sortAscending(a.info.order, b.info.order));
  };

  const getPrimaryColumn = (columns: Array<TestingLogColumn>) => {
    return columns.find((column) => column.info.primary) ?? null;
  };

  const getEmptyTestingLogColumn = (
    primaryColumn: TestingLogColumn,
  ): TestingLogColumn => {
    return {
      info: {
        id: 0 - getRandomInt(1, 10000), // Negative ID to avoid collision with real IDs
        uuid: uuidv4(),
        title: "Ad-Angle",
        primary: false,
        primaryMetric: primaryColumn.info.primaryMetric,
        secondaryMetrics: primaryColumn.info.secondaryMetrics,
        provider: primaryColumn.info.provider,
        sort: primaryColumn.info.sort,
        description: null,
        clientId: 0,
        workspaceId: primaryColumn.info.workspaceId,
        createdAt: new Date().toISOString(),
        createdBy: primaryColumn.info.createdBy,
        order: primaryColumn.info.order + 1,
        attributionWindow: primaryColumn.info.attributionWindow,
        sortBy: primaryColumn.info.sortBy,
      },
      cells: [],
    };
  };

  const getCreatedTestingLogGroup = (dto: {
    title: string;
    filter: MetricsFilter[][] | null;
    columnId: number;
    id: number;
    uuid: string;
    createdAt: string;
    order: number;
  }): TestingLogColumnGroup => {
    return {
      info: {
        id: dto.id,
        uuid: dto.uuid,
        title: dto.title,
        filter: dto.filter,
        primaryMetric: {
          name: "",
          curValue: 0,
          compareValue: 0,
          relPosition: 0,
          absPosition: 0,
        },
        metrics: [],
        order: dto.order,
        image: null,
        images: [],
        video: null,
        videos: [],
      },
      creatives: [],
      total: 0,
      pageNumber: 0,
    };
  };

  const getReportDetailPage = (reportUuid: string) => {
    return "/testing-log/detail/" + reportUuid;
  };

  const getReportDuplicatePage = (reportId: number) => {
    return "/testing-log?reportId=" + reportId + "&mode=duplicate";
  };

  const getActiveView = (
    provider: Provider,
    report: TestingLogReport,
  ): TestingLogView | null => {
    if (report.views.length <= 0) {
      return null;
    }
    return (
      report.views.find((view) => view.info.provider === provider) ??
      report.views[0]
    );
  };

  const getColumnById = (
    reports: Array<TestingLogReport>,
    columnId: number,
  ) => {
    return (
      reports
        .map((report) => report.views)
        .flat()
        .map((view) => view.columns)
        .flat()
        .find((column) => column.info.id === columnId) ?? null
    );
  };

  const getGroupById = (dto: {
    reports: Array<TestingLogReport>;
    groupId: number;
    cellIdx: number;
    columnId: number;
  }) => {
    const { groupId, cellIdx, columnId } = dto;
    return (
      dto.reports
        .map((report) => report.views)
        .flat()
        .map((view) => view.columns)
        .flat()
        .filter((column) => column.info.id === columnId)
        .map((column) => column.cells[cellIdx].groups)
        .flat()
        .find((group) => group.info.id === groupId) ?? null
    );
  };

  const deleteReport = async (reportId: number) => {
    const router = useRouter();
    const { notify } = useNotifications();
    const store = useTestingLogStore();

    const confirmed = confirm(
      "Are you sure you want to delete this report? This action cannot be undone.",
    );

    if (confirmed) {
      const errorMaybe = await store.deleteReport(reportId);
      notify(errorMaybe, "Report deleted successfully.");
      await router.push("/");
    }
  };

  const copyShareLink = (
    reportUuid: string,
    provider: Provider = Provider.META,
  ) => {
    const config = useRuntimeConfig();
    const { notify } = useNotifications();
    const { track, AnalyticsEvents } = useSegment();
    let shareLink = config.public.baseUrl + "/testing-log/share/" + reportUuid;
    if (provider != null) {
      shareLink += "?provider=" + provider;
    }
    copyTextToClipboard(shareLink);
    notify(null, "Link copied");
    track(AnalyticsEvents.SHARE_TESTING_LOG_REPORT);
  };

  const createSnapshot = async (
    reportId: number,
    provider: Provider = Provider.META,
  ) => {
    const config = useRuntimeConfig();
    const { track, AnalyticsEvents } = useSegment();
    const store = useTestingLogStore();
    const { notify } = useNotifications();
    const errorOrSnapshot = await store.createSnapshot({ reportId });
    if (typeof errorOrSnapshot === "string") {
      notify(errorOrSnapshot, "Snapshot created successfully.");
    } else if (errorOrSnapshot == null) {
      notify("Something went wrong", "");
    } else {
      notify(null, "Snapshot created successfully.");
      track(AnalyticsEvents.SNAPSHOT_TESTING_LOG_REPORT);
      let url =
        config.public.baseUrl + "/testing-log/snapshot/" + errorOrSnapshot.uuid;
      if (provider != null) {
        url += "?provider=" + provider;
      }
      await navigateTo(url, { external: true, open: { target: "_blank" } });
    }
  };

  const createReport = async (input: {
    clientId: number;
    folderId: number | null;
  }) => {
    const notificationStore = useNotificationStore();
    const store = useTestingLogStore();

    const reportIdOrError = await store.createReport({
      title: null,
      clientId: input.clientId,
      description: null,
      meta: true,
      tiktok: true,
      youtube: true,
      folderId: input.folderId,
    });

    if (reportIdOrError == null) {
      notificationStore.notify({
        type: "error",
        message: "Something went wrong",
      });
      return;
    }

    if (typeof reportIdOrError === "string") {
      notificationStore.notify({
        type: "error",
        message: reportIdOrError,
      });
      return;
    }

    const { uuid } = reportIdOrError;

    const router = useRouter();
    router.push(getReportDetailPage(uuid));
  };

  const isBestGroup = (dto: {
    group: TestingLogColumnGroup;
    cell: TestingLogColumnCell;
    primaryMetric: string;
    provider: Provider;
    customMetricRules: CustomMetricRule[];
    customConversionNames: Record<string, string>;
    showPrimaryMetricZeroValues: boolean;
  }) => {
    const { getSort } = useMetrics();
    const sort = getSort({
      metricName: dto.primaryMetric,
      provider: dto.provider,
      customConversionNames: dto.customConversionNames,
      customMetricRules: dto.customMetricRules,
    });
    const bestGroup = getCellBestGroup({
      cell: dto.cell,
      primaryMetric: dto.primaryMetric,
      sort,
      showPrimaryMetricZeroValues: dto.showPrimaryMetricZeroValues,
    });
    return bestGroup != null && bestGroup.info.id === dto.group.info.id;
  };

  const getCellBestGroup = (dto: {
    cell: TestingLogColumnCell;
    primaryMetric: string;
    sort: Sort;
    showPrimaryMetricZeroValues: boolean;
  }) => {
    return dto.cell.groups
      .filter((group) => {
        if (dto.showPrimaryMetricZeroValues) {
          return true;
        }
        const primaryMetric = group.info.metrics.find(
          (metric) => metric.name === dto.primaryMetric,
        );
        return primaryMetric != null && primaryMetric.curValue > 0;
      })
      .reduce(
        (acc, group) => {
          if (acc == null) {
            return group;
          }
          if (group.info.metrics.length === 0) {
            return acc;
          }
          const groupMetric = group.info.metrics.find(
            (metric) => metric.name === dto.primaryMetric,
          );
          const accMetric = acc.info.metrics.find(
            (metric) => metric.name === dto.primaryMetric,
          );
          if (groupMetric == null || accMetric == null) {
            return acc;
          }
          return (dto.sort === Sort.DESC &&
            groupMetric.curValue > accMetric.curValue) ||
            (dto.sort === Sort.ASC && groupMetric.curValue < accMetric.curValue)
            ? group
            : acc;
        },
        null as TestingLogColumnGroup | null,
      );
  };

  const getCellSortedGroups = (dto: {
    cell: TestingLogColumnCell;
    primaryMetric: string;
    showPrimaryMetricZeroValues: boolean;
    provider: Provider;
    customMetricRules: CustomMetricRule[];
    customConversionNames: Record<string, string>;
  }): Array<TestingLogColumnGroup> => {
    const { getSort } = useMetrics();
    const sort = getSort({
      metricName: dto.primaryMetric,
      provider: dto.provider,
      customConversionNames: dto.customConversionNames,
      customMetricRules: dto.customMetricRules,
    });
    const sortFn = sort === Sort.ASC ? sortAscending : sortDescending;
    return dto.cell.groups
      .filter((group) => {
        if (dto.showPrimaryMetricZeroValues) {
          return true;
        }
        const primaryMetric = group.info.metrics.find(
          (metric) => metric.name === dto.primaryMetric,
        );
        return primaryMetric != null && primaryMetric.curValue > 0;
      })
      .sort((a, b) => {
        const aMetric = a.info.metrics.find(
          (metric) => metric.name === dto.primaryMetric,
        );
        const bMetric = b.info.metrics.find(
          (metric) => metric.name === dto.primaryMetric,
        );
        if (aMetric == null || bMetric == null) {
          return 0;
        }
        return sortFn(aMetric.curValue, bMetric.curValue);
      });
  };

  return {
    getMappedTestingLogReportSnapshpts,
    getMappedTestingLogReports,
    getMappedTestingLogViews,
    getMappedTestingLogColumns,
    getMappedTestingLogCells,
    getMappedTestingLogGroups,
    getPrimaryColumn,
    getCreatedTestingLogGroup,
    getEmptyTestingLogColumn,
    getReportDetailPage,
    getReportDuplicatePage,
    getActiveView,
    deleteReport,
    copyShareLink,
    createReport,
    createSnapshot,
    getColumnById,
    getGroupById,
    isBestGroup,
    getCellSortedGroups,
  };
};
